import React, { useContext, useEffect, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import ArticleItem from './ArticleItem'
import Pagination from './Pagination'
import ArticleContext from '../../context/article/articleContext'
import Spinner from '../layout/Spinner'

const ArticleList = () => {
  const articleContext = useContext(ArticleContext)
  const { articles, getPage, getArticleIDs, article_ids, loading } = articleContext

  useEffect(() => {
    getPage('1')
    // eslint-disable-next-line
  }, [article_ids])

  if(loading)
    return <Spinner />
  else
  return (
    <div>
      <div className='text-center px-1'>
        <h1 className="text-dark d-inline">Hacker <i className="fas fa-skull-crossbones text-danger"></i> News</h1>
        <i className="fas fa-sync-alt fa-2x text-success refresh-icon" 
          data-tip='Refresh'
          onClick={() => getArticleIDs()}>
        </i>
        <ReactTooltip place="top" type="dark" effect="solid" className='tooltips'/>
      </div>
      <ul className="list-group">
        {articles.length === 0 ? (
          <Fragment>
          <i className="fas fa-question fa-8x my-5 text-danger text-center"></i>
          <p className="lead text-center">No articles found...</p>
          </Fragment>
        ) : (
          articles.map(article => (
            <ArticleItem key={article.id} article={article} />
          ))
        )}
        <Pagination />
      </ul>
    </div>
  )
}
export default ArticleList