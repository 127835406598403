import React, { useContext, useEffect } from 'react'
import ArticleList from './articles/ArticleList'
import ArticleContext from '../context/article/articleContext'
import AuthContext from '../context/auth/authContext'
import FavoritesList from './favorites/FavoritesList'
import Spinner from '../components/layout/Spinner'

const Home = () => {
  const articleContext = useContext(ArticleContext)
  const { getArticleIDs, article_ids } = articleContext

  const authContext = useContext(AuthContext)
  const { loadUser, user } = authContext

  useEffect(() => {
    loadUser()
    getArticleIDs()
    // eslint-disable-next-line
  }, [])     

  if(article_ids.length === 0 || user === null)
    return <Spinner />
  else
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 pr-4">
          <ArticleList />
        </div>
        <div className="col-md-4">
          <FavoritesList list='favorites' title='Favorites' color='warning' />
          <FavoritesList list='read_later' title='Read Later' color='danger' />
        </div>
      </div>
    </div>
  )
}

export default Home