import React, { useContext, Fragment } from 'react'
import AuthContext from '../../context/auth/authContext'

const ArticleItem = ({ article }) => {
  const { title, by, url, score, id } = article // article obj also contains time

  const authContext = useContext(AuthContext)
  const { addFavorite,
          delFavorite,
          addReadLater,
          delReadLater,
          user: { favorites, read_later }
  } = authContext
  
  /* add or delete favorite or bookmark article*/
  const onClick = e => {
    if(e.target.getAttribute('id') === 'star') {
      if(favorites.includes(`${id}`)) {
        delFavorite(id)
      } else {
        addFavorite(id)
      }
    }
    else {
      if(read_later.includes(`${id}`)) {
        delReadLater(id)
      } else {
        addReadLater(id)
      }
    }
  }

  return (
    <Fragment>
      <li className="list-group-item mylist-item">
        <p className="d-inline">{title}</p>
        <i className={favorites.includes(`${id}`) ? "fas fa-star px-1 text-warning" : "far fa-star px-1"}
          id="star"
          data-tip='Toggle Favorite'
          onClick={onClick}>
        </i>
        <i className={read_later.includes(`${id}`) ? "fas fa-bookmark px-1 text-danger" : "far fa-bookmark px-1"}
          id="bookmark"
          data-tip='Toggle Read Later'
          onClick={onClick}>
        </i>
        <a href={url} target='_blank' rel='noopener noreferrer'>
          <i className="fas fa-external-link-alt px-1"
            id="visit"
            data-tip='View Article'>
          </i>
        </a>
        <span className="badge badge-primary mx-1" data-tip='Score' id='score'>{score}</span>
        <div className="blockquote-footer">by {by}</div>
      </li>
    </Fragment>
  )
}

export default ArticleItem