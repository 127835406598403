import React, { useContext, useState, useEffect } from 'react'
import AuthContext from '../../context/auth/authContext'
import axios from 'axios'

const FavoriteItem = ({ id, color, name }) => {
  const authContext = useContext(AuthContext)
  const { delFavorite, delReadLater } = authContext
  
  useEffect(() => {
    getFavorite()
    // eslint-disable-next-line
  }, [])

  const [ favorite, setFavorite ] = useState(null)

  const getFavorite = async () => {
    const res = await axios.get(`https://hacker-news.firebaseio.com/v0/item/${id}.json`)
    setFavorite(res.data)
  }

  const deleteFav = e => {
    if(e.target.getAttribute('name') === 'favorites') {
      delFavorite(id)
    }
    if(e.target.getAttribute('name') === 'read_later') {
      delReadLater(id)
    }
  }

  return (
    <div className={`bg-${color} pl-4 border-${color} my-1 rounded-left`}>
      <li className="list-group-item d-flex align-items-center favlist-item bg-light rounded-0">
        {favorite !== null ? (
          <a href={favorite.url} target='_blank' rel='noopener noreferrer'>{favorite.title}</a>
        ) : (
          <p>Error Getting Favorite</p>
        )}
        <i name={name} 
          className="fas fa-trash-alt trash-icon ml-auto pl-2"
          data-tip='Remove from list'
          onClick={deleteFav}>
        </i>
      </li>
    </div>
  )
}

export default FavoriteItem