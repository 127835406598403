import React from 'react'
import PirateImage from '../pirate-ship.png'

const About = () => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-md-6 m-2">
          <h3>About this App</h3>
          <p>This application serves no purpose.</p>
          <div className="blockquote-footer">PirateLife Web Devs</div>
        </div>
        <div className="col-md-6 mt-4">
          <img src={PirateImage} className='img-fluid' alt=""/>
        </div>
      </div>
    </div>
  )
}

export default About
