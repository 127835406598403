export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const GET_USER = 'GET_USER'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const GET_ARTICLE_IDS = 'GET_ARTICLE_IDS'
export const GET_ARTICLE_DETAILS = 'GET_ARTICLE_DETAILS'
export const GET_PAGE = 'GET_PAGE'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const LOGOUT = 'LOGOUT'