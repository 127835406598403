import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../context/auth/authContext'
import AlertContext from '../context/alert/alertContext'

const Register = (props) => {
  const authContext = useContext(AuthContext)
  const { register, isAuthenticated, error, clearErrors } = authContext

  const alertContext = useContext(AlertContext)
  const { setAlert} = alertContext

  useEffect(() => {
    if(isAuthenticated) {
      props.history.push('/')
    }
    if(error !== null) {
      setAlert(error, 'danger')
      clearErrors()
    }
    // eslint-disable-next-line
  }, [isAuthenticated, error])

  const [ user, setUser ] = useState({
    name: '',
    email: '',
    password: '',
    password2: ''
  })

  const { name, email, password, password2 } = user

  const onChange = e => setUser({ ...user, [e.target.name]: e.target.value })
 
  const onSubmit = e => {
    e.preventDefault()
    if(name === '' || email === '' || password === '' || password2 === '') {
      setAlert('All fields are required', 'warning')
    } else if (password !== password2) {
      setAlert('Passwords do not match', 'warning')
    } else {
      register({ name, email, password })
    }
  }

  return (
    <section id="register"> 
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mx-auto">
            <div className="card">
              <div className="card-header bg-light text-center mb-3">
                <p className="lead mt-3">Registration Form</p>
              </div>
              <div className="card-body">
                <form onSubmit={onSubmit}>
                                    
                  <div className="form-group mb-4">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-envelope"></i></div>
                      </div>
                      <input className="form-control" type="email" name="email" value={email} onChange={onChange} id="email" placeholder="Enter email address" />
                    </div>
                  </div>
  
                  <div className="form-group mb-4">
                    <div className="input-group">
                      <div className="input-group-text"><i className="fas fa-user"></i></div>
                      <input className="form-control" type="name" name="name" value={name} onChange={onChange} id="name" placeholder="Enter name" />
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-lock"></i></div>
                      </div>
                      <input className="form-control" type="password" name="password" value={password} onChange={onChange} id="password" placeholder="Enter password" />
                    </div>
                  </div>

                  <div className="form-group mb-5">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-lock"></i></div>
                      </div>
                      <input className="form-control" type="password" name="password2" value={password2} onChange={onChange} id="password2" placeholder="Confirm password" />
                    </div>
                  </div>
                                 
                  <button className="btn btn-primary btn-block mb-4" type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Register