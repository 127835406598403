import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import piratelogo from '../../skull-bones-icon.png'
import AuthContext from '../../context/auth/authContext'

const Navbar = () => {
  const authContext = useContext(AuthContext)
  const { logout, isAuthenticated } = authContext

  let location = useLocation()

  const onLogout = () => {
    logout()
  }

  return (
    <nav className="navbar navbar-expand-sm bg-dark navbar-dark mb-5">
      <div className="container">
        <Link to='/' className='navbar-brand'>
          <img src={piratelogo} alt="" width="75" height="75" />
          <h3 className="d-inline align-middle nav-title">Pirate Life</h3>
        </Link>
        <button className="navbar-toggler" data-toggle="collapse" data-target="#navMenu">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navMenu">
          <ul className="navbar-nav ml-auto">

            {isAuthenticated && 
              <li className="nav-item">
                <Link to='/' 
                  className={location.pathname==='/' ? "nav-link active" : "nav-link"}>Home
                </Link>
              </li>
            }
            {isAuthenticated &&
            <li className="nav-item">
              <Link to='/about' 
                className={location.pathname==='/about' ? "nav-link active" : "nav-link"}>About
              </Link>
            </li>
            }
            {!isAuthenticated &&
            <li className="nav-item">
              <Link to='/login' 
                className={location.pathname==='/login' ? "nav-link active" : "nav-link"}>Login
              </Link>
            </li>
            }
            {!isAuthenticated &&
            <li className="nav-item">
              <Link to='/register'
                className={location.pathname==='/register' ? "nav-link active" : "nav-link"}>Register
              </Link>
            </li>
            }
            {isAuthenticated &&
            <li className="nav-item">
              <a onClick={onLogout} href="#!" className='nav-link'>Logout</a>
            </li>
            }

          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
