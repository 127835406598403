import React, { useReducer } from 'react'
import axios from 'axios'
import ArticleContext from './articleContext'
import ArticleReducer from './articleReducer'
import {
  GET_ARTICLE_DETAILS,
  GET_ARTICLE_IDS,
  GET_PAGE,
} from '../types'

const ArticleState = props => {
  const initialState = {
    article_ids: [],
    articles: [],
    loading: true,
    page: {
      num: 0,
      index: 0,
      size: 8
    }
  }

  const [state, dispatch] = useReducer(ArticleReducer, initialState)

  // Get Articles
  const getArticleIDs = async () => {
    const res = await axios.get(`https://hacker-news.firebaseio.com/v0/topstories.json`)
    dispatch({ type: GET_ARTICLE_IDS, payload: res.data })
    }

  // Get Article Details
  const getArticleDetails = async (id) => {
    const res = await axios.get(`https://hacker-news.firebaseio.com/v0/item/${id}.json`)
    dispatch({ type: GET_ARTICLE_DETAILS, payload: res.data })
  }

  // Get Page of Articles
  const getPage = async (direction) => {
    let new_array = []
    let new_page = { num: state.page.num, index: state.page.index, size: state.page.size }
    if(direction === "next") {
      new_page.num++
      new_page.index = new_page.index + new_page.size
    } else if(direction === "prev") {
      new_page.num--
      new_page.index = new_page.index - new_page.size
    } else {
      new_page.num = 0
      new_page.index = 0
    }
    // fetch next page.size number of article objects
    for(let i = new_page.index; i < new_page.index + new_page.size; i++) {
      let res = await axios.get(`https://hacker-news.firebaseio.com/v0/item/${state.article_ids[i]}.json`)
      new_array.push(res.data)
    }
    //console.log(new_array)
    dispatch({ type: GET_PAGE, payload: { articles: new_array, page: new_page } })
  }

  return <ArticleContext.Provider
    value={{
      article_ids: state.article_ids,
      articles: state.articles,
      loading: state.loading,
      page: state.page,
      getArticleIDs,
      getArticleDetails,
      getPage,
    }}
  >
    {props.children}
  </ArticleContext.Provider>
}

export default ArticleState