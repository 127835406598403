import {
  GET_ARTICLE_IDS,
  GET_ARTICLE_DETAILS,
  GET_PAGE,
} from '../types'

export default (state, action) => {
  switch(action.type) {
    case GET_ARTICLE_IDS:
      return {
        ...state,
        article_ids: action.payload,
        articles: [],
        loading: true,
        page: { num: 0, index: 0, size: 8 }
      }
    case GET_ARTICLE_DETAILS:
      return {
        ...state,
        articles: [action.payload, ...state.articles]
      }
    case GET_PAGE:
      return {
        ...state,
        page: action.payload.page,
        articles: action.payload.articles,
        loading: false
      }
    default:
      return state
  }
}