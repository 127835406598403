import React from 'react'

const Footer = () => {
  return (
    <footer className="py-4 bg-danger">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <p className="lead text-white">Copyright &copy; { new Date().getFullYear() }</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer