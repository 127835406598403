import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../context/auth/authContext'
import AlertContext from '../context/alert/alertContext'

const Login = (props) => {
  const authContext = useContext(AuthContext)
  const { login, isAuthenticated, error, clearErrors } = authContext

  const alertContext = useContext(AlertContext)
  const { setAlert } = alertContext

  const [user, setUser] = useState({ email: '', password: ''})
  const { email, password } = user

  useEffect(() => {
    if(isAuthenticated) {
      props.history.push('/')
    }
    if(error !== null) {
      setAlert(error, 'danger')
      clearErrors()
    }
    // eslint-disable-next-line
  }, [isAuthenticated, error])

  const onChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const onSubmit = e => {
    e.preventDefault()
    if(email === '' || password === '') {
      setAlert('email or password is blank', 'warning')
    } else {
      login({ email, password })
    }
  }

  return (
    <section id="login">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mx-auto">
            <div className="card">
              <div className="card-header bg-light mb-3">
                Login
              </div>
              <div className="card-body">
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-envelope"></i></div>
                      </div>
                      <input className="form-control" type="email" id="email" name="email" value={email} onChange={onChange} placeholder="Enter email address" />
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <label htmlFor="password">Password</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-lock"></i></div>
                      </div>
                      <input className="form-control" type="password" name="password" value={password} onChange={onChange} id="password" placeholder="Enter password" />
                    </div>
                  </div>
                  <button className="btn btn-primary btn-block" type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
