import React, { useReducer } from 'react'
import axios from 'axios'
import setAuthToken from '../../utils/setAuthToken'
import AuthContext from './authContext'
import AuthReducer from './authReducer'
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_ERROR,
  CLEAR_ERRORS,
  USER_LOADED,
  LOGOUT,
} from '../types'

const AuthState = props => {
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    user: null,
    error: null
  }

const [state, dispatch] = useReducer(AuthReducer, initialState)

// Load User
const loadUser = async () => {
  if(localStorage.token) {
    setAuthToken(localStorage.token)
  }
  try {
    const res = await axios.get('/api/user')
    dispatch({ type: USER_LOADED, payload: res.data })
  } catch (err) {
    dispatch({ type: AUTH_ERROR, payload: err.response.data.msg })
  }
}

// Register User
const register = async ({ name, email, password }) => {
  const config = { headers: { 'Content-Type': 'application/json' }}
  try {
    const res = await axios.post('/api/user/register', { name, email, password }, config)
    dispatch({ type: REGISTER_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: REGISTER_FAIL, payload: err.response.data.msg })
  }
}

// Login User
const login = async ({ email, password }) => {
  const config = { headers: { 'Content-Type': 'application/json' }}
  try {
    const res = await axios.post('/api/user', { email, password }, config)
    dispatch({ type: LOGIN_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: LOGIN_FAIL, payload: err.response.data.msg })
  }
}

// Logout User
const logout = () => {
  dispatch({ type: LOGOUT })
}

// Add Favorite
const addFavorite = async id => {
  if(localStorage.token) {
    setAuthToken(localStorage.token)
  }
  const data = { 'articleID': id }
  const config = { headers: { 'Content-Type': 'application/json' }}
  try {
    const res = await axios.post('/api/favorites', data, config)
    dispatch({ type: USER_LOADED, payload: res.data })
  } catch (err) {
    dispatch({ type: AUTH_ERROR, payload: err.response.data.msg })
  }
}

// Delete Favorite
const delFavorite = async id => {
  if(localStorage.token) {
    setAuthToken(localStorage.token)
  }
  try {
    const res = await axios.delete(`/api/favorites/${id}`)
    dispatch({ type: USER_LOADED, payload: res.data })
  } catch (err) {
    dispatch({ type: AUTH_ERROR, payload: err.response.data.msg })
  }
}

// Add Read Later
const addReadLater = async id => {
  if(localStorage.token) {
    setAuthToken(localStorage.token)
  }
  const data = { 'articleID': id }
  const config = { headers: { 'Content-Type': 'application/json' }}
  try {
    const res = await axios.post('/api/readlater', data, config)
    dispatch({ type: USER_LOADED, payload: res.data })
  } catch (err) {
    dispatch({ type: AUTH_ERROR, payload: err.response.data.msg })
  }
}

// Delete Read Later
const delReadLater = async id => {
  if(localStorage.token) {
    setAuthToken(localStorage.token)
  }
  try {
    const res = await axios.delete(`/api/readlater/${id}`)
    dispatch({ type: USER_LOADED, payload: res.data })
  } catch (err) {
    dispatch({ type: AUTH_ERROR, payload: err.response.data.msg })
  }
}

// Clear Errors
const clearErrors = () => {
  dispatch({ type: CLEAR_ERRORS })
}

return (
  <AuthContext.Provider
    value = {{
      token: state.token,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      error: state.error,
      register,
      login,
      loadUser,
      logout,
      clearErrors,
      addFavorite,
      delFavorite,
      addReadLater,
      delReadLater
    }}>
    { props.children }
  </AuthContext.Provider>
  )
}

export default AuthState