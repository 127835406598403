import React, { useContext } from 'react'
import ArticleContext from '../../context/article/articleContext'

const Pagination = () => {
  const articleContext = useContext(ArticleContext)
  const { page, getPage, article_ids } = articleContext

  const next_page = (e) => {
    e.preventDefault()
    getPage('next')
  }

  const prev_page = (e) => {
    e.preventDefault()
    getPage('prev')
  }

  return (
    <nav>
      <ul className="pagination justify-content-center my-2">
        <li className={"page-item " + (page.num===0 ? 'disabled' : '')}>
          <a className="page-link" href="!#" id="prev" onClick={prev_page}>
              <span>&laquo;</span>
              <span className="sr-only">Previous</span>
          </a>
        </li>
        <li className="page-item disabled">
          <a className="page-link text-dark" href='!#'>{page.num+1}</a>
        </li>
        <li className={"page-item " + (page.num===(Math.floor(article_ids.length/page.size)) ? 'disabled' : '')}>
          <a className="page-link" href="!#" id="next" onClick={next_page}>
            <span>&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination