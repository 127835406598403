import React, { useContext, Fragment } from 'react'
import FavoriteItem from './FavoriteItem'
import Spinner from '../layout/Spinner'
import AuthContext from '../../context/auth/authContext'

const FavoritesList = ({ list, title, color }) => {
  const authContext = useContext(AuthContext)
  const { user } = authContext

  let num_items = null
  // get number of items for either favorite or read_later list
  if(list === 'favorites') {
    num_items = user.favorites.length
  } else {
    num_items = user.read_later.length
  }

  if(user === null)
    return <Spinner />
  else
  return (
    <Fragment>
      <h1 className="text-dark text-center">{title}</h1>
      <ul className="list-group mb-5">
        {num_items === 0 ? (  
          <div className={`bg-success pl-4 border-success my-1 rounded-left`}>
          <li className="list-group-item d-flex align-items-center bg-light rounded-0">
            <p>No Items Found</p>
          </li>
          </div>
        ) : (
          list==='favorites' ?
            user.favorites.map(id => (<FavoriteItem key={id} color={color} id={id} name={list} />))
          : user.read_later.map(id => (<FavoriteItem key={id} color={color} id={id} name={list} />))
        )}
      </ul>
    </Fragment>
  )
}

export default FavoritesList